/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');
global.$ = $;
global.jQuery = $;

require('popper.js');
require('./bootstrap.min');
require('./jquery.slicknav.min');
require('./metisMenu.min');
require('jquery-validation');
require('./vendor/bootstrap-editable.min');
require('jquery-validation/dist/localization/messages_es.min');
require('./jquery.slimscroll.min');
require('./plugins');
require('./scripts');
require('./custom');

