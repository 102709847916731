$(function () {
    $("form").each(function () {
        let validator = $(this).validate({
            ignore: ".ignore",
        });
        validator.resetForm();
    });
});

$(".imagefile").change(function () {
    readURL(this);
});


function readURL(input) {
    if (input.files && input.files[0]) {
        let reader = new FileReader();
        reader.onload = function (e) {
            $('#' + $(input).attr('data-target')).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
    }
}